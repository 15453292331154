// Equivalent to flex: 1 1 'auto'
.flex-auto {
  flex: auto;
}

// Equivalent to flex: 0 0 'auto'
.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1 1 0;
}
