$sizes: 10 11 12 14 16 18 20 24 28 32 40;

@each $size in $sizes {
  .font-size-#{$size} {
    font-size: #{$size}px;
  }
}

$fontWeights: 100 200 300 400 500 600 700 800 900;
@each $weight in $fontWeights {
  .font-weight-#{$weight} {
    font-weight: #{$weight};
  }
}

.white-space-pre-line {
  white-space: pre-line;
}
