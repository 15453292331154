/**
 * This is likely a workaround and probably not necessary, once we implement the actual card widget.
 * Requires regression testing before/after removing.
 */
.mat-card-header-text {
  overflow: hidden;
}

/**
 * This is likely a workaround and probably not necessary, once we implement the actual card widget.
 * Requires regression testing before/after removing.
 */
.mat-card-avatar {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 50%;
  flex: 0 0 40px;
}

/**
 * This is likely a workaround and probably not necessary, once we implement the actual card widget.
 * Requires regression testing before/after removing.
 */
tb-text.mat-card-avatar {
  line-height: 40px;
}
