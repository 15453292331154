.display-grid {
  display: grid;
}

$rowColOptions: 1 2 3 4 5 6 7 8;

@each $size in $rowColOptions {
  .grid-cols-#{$size} {
    grid-template-columns: repeat(#{$size}, minmax(0, 1fr));
  }
  .grid-rows-#{$size} {
    grid-template-rows: repeat(#{$size}, minmax(0, 1fr));
  }
}

$spaceValues: auto start end center stretch;

@each $spaceValue in $spaceValues {
  .place-self-#{$spaceValue} {
    place-self: #{$spaceValue};
  }
}

.grid-rows-min-content {
  grid-template-rows: min-content;
}

.col-span-full {
  grid-column: 1 / -1;
}
