.full-height {
  height: 100%;
}

.border-none {
  border: none;
}

$radii: 4 8 12 16 20 24 28 32;

@each $radius in $radii {
  .border-radius-#{$radius} {
    border-radius: #{$radius}px;
  }
}

.display-inline {
  display: inline;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}
