@use "sass:string";

$spaceValues: 0 2 4 6 8 12 16 20 24 32 40;
$types: padding margin;

@each $value in $spaceValues {
  @each $type in $types {
    $typeShorthand: string.slice($type, 1, 1);

    .#{$typeShorthand}-#{$value} {
      #{$type}: #{$value}px !important;
    }
    .#{$typeShorthand}x-#{$value} {
      #{$type}-left: #{$value}px !important;
      #{$type}-right: #{$value}px !important;
    }
    .#{$typeShorthand}y-#{$value} {
      #{$type}-top: #{$value}px !important;
      #{$type}-bottom: #{$value}px !important;
    }
    .#{$typeShorthand}b-#{$value} {
      #{$type}-bottom: #{$value}px !important;
    }
    .#{$typeShorthand}t-#{$value} {
      #{$type}-top: #{$value}px !important;
    }
    .#{$typeShorthand}l-#{$value} {
      #{$type}-left: #{$value}px !important;
    }
    .#{$typeShorthand}r-#{$value} {
      #{$type}-right: #{$value}px !important;
    }
  }
}
