// Import all the tools needed to customize the theme and extract parts of it
@use '@angular/material' as mat;
@import '@angular/material/theming';

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin widgets-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $palettes: primary accent warn;
  $hues: 50 100 200 300 400 500 600 700 800 900 default lighter darker A100 A200 A400 A700;
  $opacities: 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95;

  .outlined {
    border: solid 1px;
    border-color: mat.get-color-from-palette($foreground, base, 0.12);
  }

  .hidden {
    display: none;
  }

  @each $paletteName in $palettes {
    $palette: map-get($theme, $paletteName);
    .color-#{$paletteName} {
      color: mat.get-color-from-palette($palette);
    }
    .color-#{$paletteName}-contrast {
      color: mat.get-contrast-color-from-palette($palette,default);
    }
    .background-color-#{$paletteName} {
      background-color: mat.get-color-from-palette($palette);
    }
    .background-color-#{$paletteName}-contrast {
      background-color: mat.get-contrast-color-from-palette($palette,default);
    }
    @each $opacity in $opacities {
      .color-#{$paletteName}-opacity-#{$opacity} {
        color: mat.get-color-from-palette($palette, calc($opacity / 100));
      }
      .color-#{$paletteName}-contrast-opacity-#{$opacity} {
        $color: mat.get-contrast-color-from-palette($palette,default);
        @if (type-of($color) == color) {
          color: rgba($color, calc($opacity / 100));
        }
      }
      .background-color-#{$paletteName}-opacity-#{$opacity} {
        background-color: mat.get-color-from-palette($palette, calc($opacity / 100));
      }
      .background-color-#{$paletteName}-contrast-opacity-#{$opacity} {
        $color: mat.get-contrast-color-from-palette($palette,default);
        @if (type-of($color) == color) {
          background-color: rgba($color, calc($opacity / 100));
        }
      }
    }
    @each $hue in $hues {
      .color-#{$paletteName}-hue-#{$hue} {
        color: mat.get-color-from-palette($palette, $hue);
      }
      .color-#{$paletteName}-hue-#{$hue}-contrast {
        color: mat.get-contrast-color-from-palette($palette, $hue);
      }
      .background-color-#{$paletteName}-hue-#{$hue} {
        background-color: mat.get-color-from-palette($palette, $hue);
      }
      .background-color-#{$paletteName}-hue-#{$hue}-contrast {
        background-color: mat.get-contrast-color-from-palette($palette, $hue);
      }
      @each $opacity in $opacities {
        .color-#{$paletteName}-hue-#{$hue}-opacity-#{$opacity} {
          color: mat.get-color-from-palette($palette, $hue, calc($opacity / 100));
        }
        .color-#{$paletteName}-hue-#{$hue}-contrast-opacity-#{$opacity} {
          $color: mat.get-contrast-color-from-palette($palette,$hue);
          @if (type-of($color) == color) {
            color: rgba($color, calc($opacity / 100));
          }
        }
        .background-color-#{$paletteName}-hue-#{$hue}-opacity-#{$opacity} {
          background-color: mat.get-color-from-palette($palette, $hue, calc($opacity / 100));
        }
        .background-color-#{$paletteName}-hue-#{$hue}-contrast-opacity-#{$opacity} {
          $color: mat.get-contrast-color-from-palette($palette,$hue);
          @if (type-of($color) == color) {
            background-color: rgba($color, calc($opacity / 100));
          }
        }
      }
    }
  }

  @each $opacity in $opacities {
    .foreground-opacity-#{$opacity} {
      color: mat.get-color-from-palette($foreground, base, calc($opacity / 100));
    }
    .background-opacity-#{$opacity} {
      background-color: mat.get-color-from-palette($background, background, calc($opacity / 100));
    }
  }

  .tb-widget {
    @include mat.elevation-transition;
  }

  // BasePage
  .page {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.xsmall, &.small, &.medium {
      tb-toolbar {
        z-index: 10;
        position: fixed;
      }
    }

    mat-sidenav.side-content-container {
      padding: 20px 16px;
    }

    mat-sidenav.navigation-container {
      min-width: 256px;

      > .mat-drawer-inner-container {
        display: flex;
      }
    }

    mat-sidenav-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      mat-sidenav-content.mat-drawer-content {

        flex: 1;
        display: flex;
        flex-direction: column;

        .page-content-viewport {
          background-color: mat.get-color-from-palette($background, default);
          flex: 1;

          .page-content-wrapper {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            margin: auto;

            > * {
              max-width: 100%;
            }

            &.centered {
              justify-content: center;
            }
          }
        }

      }

    }

    &.small.landscape tb-toolbar {
      mat-toolbar {
        min-height: 48px;
      }
      mat-toolbar-row {
        height: 48px;
      }
    }

    &.xsmall {
      .page-content-viewport {
        padding: 8px;
      }

      mat-toolbar-row {
        padding: 0 8px;
      }

      mat-sidenav-container {
        flex: 1 0 auto;

        mat-sidenav.side-content-container {
          width: 100%;
        }
      }
    }

    &.xsmall, &.small {
      mat-sidenav.navigation-container {
        width: calc(100% - 56px);
        max-width: 320px;
      }
    }

    &.small, &.medium {
      .page-content-viewport {
        padding: 16px;
      }

      mat-toolbar-row {
        padding: 0 16px;
      }
    }

    &.medium, &.large {
      max-height: 100vh;

      mat-sidenav-content .page-content-viewport {
        overflow-y: auto;
        max-height: 100vh;
      }
      mat-sidenav.side-content-container {
        min-width: 320px;
        max-width: calc(100% - 56px);
      }
    }

    &.large {
      mat-sidenav.navigation-container {
        max-width: 400px;
      }
      .page-content-viewport {
        padding: 24px;
      }
      mat-toolbar-row {
        padding: 0 24px;
      }
    }
  }

  // Table
  tb-data-table {
    background-color: mat.get-color-from-palette($background, 'card');
  }

  // Toolbar
  tb-toolbar:not(.transparent) > .mat-toolbar {
    --mat-toolbar-container-background-color: #{mat.get-color-from-palette($background, card)};
  }

}


